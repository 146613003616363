const FacebookIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="8.304"
    height="16.608"
    viewBox="0 0 8.304 16.608"
  >
    <title>Facebook</title>
    <path
      fill="white"
      d="M8.076 5.536H6V8.3h2.076v8.3h3.46V8.3h2.52l.244-2.764h-2.764V4.382c0-.661.133-.922.772-.922h2V0h-2.639C9.18 0 8.076 1.1 8.076 3.194z"
      transform="translate(-6)"
    />
  </svg>
);

const TwitterIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15.69"
    height="12.751"
    viewBox="0 0 15.69 12.751"
  >
    <title>Twitter</title>
    <path
      fill="white"
      d="M15.69 3.758a6.427 6.427 0 01-1.849.507 3.224 3.224 0 001.415-1.781 6.448 6.448 0 01-2.044.781A3.221 3.221 0 007.727 6.2a9.138 9.138 0 01-6.635-3.363 3.223 3.223 0 001 4.3 3.205 3.205 0 01-1.457-.4 3.221 3.221 0 002.582 3.2 3.226 3.226 0 01-1.454.055 3.221 3.221 0 003.007 2.235A6.472 6.472 0 010 13.553 9.113 9.113 0 004.934 15a9.1 9.1 0 009.149-9.575 6.554 6.554 0 001.607-1.667z"
      transform="translate(0 -2.248)"
    />
  </svg>
);

const LinkedinIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="13.598"
    height="13.032"
    viewBox="0 0 13.598 13.032"
  >
    <title>Linkedin</title>
    <path
      fill="white"
      d="M2.822 2.416A1.405 1.405 0 111.416 1a1.411 1.411 0 011.406 1.416zm.011 2.55H0v9.065h2.833zm4.523 0H4.541v9.065h2.815V9.273c0-2.646 3.416-2.862 3.416 0v4.759H13.6V8.291c0-4.465-5.055-4.3-6.243-2.1V4.966z"
      transform="translate(0 -1)"
    />
  </svg>
);

const WhatsAppIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 0 14 14"
  >
    <title>WhatsApp</title>
    <path
      fill="white"
      d="M 7.003 1 C 3.692 1 1.001 3.691 1 6.997 C 0.999 8.131 1.316 9.235 1.917 10.189 L 2.06 10.416 L 1.453 12.63 L 3.725 12.034 L 3.944 12.163 C 4.865 12.71 5.921 12.999 6.997 13 L 7 13 C 10.307 13 12.998 10.309 13 7.003 C 13.001 5.4 12.377 3.893 11.245 2.759 C 10.111 1.625 8.605 1.001 7.003 1 Z"
      transform="translate(0 -1)"
    />
  </svg>
);

const Arrow = () => (
  <svg
    viewBox="0 0 24 24"
    fill="currentColor"
    height="1em"
    width="1em"
  >
    <title>Arrow</title>
    <path
      fill="var(--primaryColor)"
      d="M11 8.414V18h2V8.414l4.293 4.293 1.414-1.414L12 4.586l-6.707 6.707 1.414 1.414z"
    />
  </svg>
  );


export { FacebookIcon, TwitterIcon, LinkedinIcon, WhatsAppIcon, Arrow };
